//@flow
import React, { forwardRef } from 'react';
import type { Node } from 'react';

import Opacity from '../Animations/Opacity';

import './DropdownContainer.scss';

type Props = {
  children: Node,
  isActive: boolean | null,
  toggleFunction: Function | null
};

const DropDownContainer = forwardRef<Object, Object>(({ children, isActive, toggleFunction }: Props, ref: Object) => (
  <>
    {isActive && toggleFunction ? <span key='dropdown-close-area' className='dropdown-container-close-area' onClick={ toggleFunction } tabIndex='-1' /> : null}
    <span key='dropdown-container' className='dropdown-container'>
      {isActive && (
        <Opacity speed={ 100 }>
          <span className='dropdown-container-content' ref={ ref }>
            {children}
          </span>
        </Opacity>
      )}
    </span>
  </>
));

export default DropDownContainer;
