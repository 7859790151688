//@flow
import React from 'react';
import type { Node } from 'react';
import './index.scss';

type Props = {
  label?: string | Node
};

const InputLoading = ({ label }: Props) => (
  <div>
    <p className='loadinginput__label'>{label}</p>
    <input className='loadinginput__input' />
  </div>
);

export default InputLoading;
