//@flow
import React from 'react';
import './index.scss';

const TextLoading = ({ darkTheme = false }: {darkTheme?: boolean }) => {
  let className = 'loadingtext';
  if (darkTheme) {
    className += ' loadingtext--dark';
  }
  return <span className={ className } />;
};

export default TextLoading;
