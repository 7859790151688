//@flow
import React, { PureComponent } from 'react';

import type { ComparisonBarChartDataType } from '../../../types';

const { ResponsiveBar } = require('@nivo/bar');

type Props = {
  data: Array<ComparisonBarChartDataType>
};

class ComparisonBarChart extends PureComponent<Props> {
  static defaultProps = {
  }

  render() {
    const { data } = this.props;
    let dataMin = 0;
    let dataMax = 0;

    dataMin = (Math.min(data[0].current, data[0].initial, data[0].retired || data[0].initial) * 0.95);
    dataMax = (Math.max(data[0].current, data[0].initial, data[0].retired || data[0].initial) * 1.05);

    const chartData = {
      id: data[0].name,
      ...data[0],
    };

    return (
      <>
        <p className='small blue'><b>{data[0].name}</b></p>
        <ResponsiveBar
          data={ [chartData] }
          keys={ [
            'current',
            'initial',
            'retired',
          ] }
          margin={{
            'top': 10,
            'right': 30,
            'bottom': 50,
            'left': 0,
          }}
          minValue={ dataMin }
          maxValue={ dataMax }
          padding={ 0 }
          borderRadius={ 20 }
          innerPadding={ 10 }
          groupMode='grouped'
          layout='horizontal'
          colors={ ['#4d697e', '#01d263', '#b92424'] }
          colorBy='id'
          borderColor='inherit:darker(1.6)'
          axisBottom={{
            'orient': 'bottom',
            'tickSize': 5,
            'tickPadding': 5,
            'tickRotation': -20,
            'legend': ' ',
            'legendPosition': 'middle',
            'legendOffset': 10,
            'tickValues': 5,
          }}
          labelTextColor='inherit:darker(1.6)'
          animate
          motionStiffness={ 90 }
          motionDamping={ 15 }
          enableGridX
          enableGridY
          defs={ [
            {
              'id': 'dots',
              'type': 'patternDots',
              'background': 'inherit',
              'color': '#38bcb2',
              'size': 4,
              'padding': 1,
              'stagger': true,
            },
            {
              'id': 'lines',
              'type': 'patternLines',
              'background': 'inherit',
              'color': '#eed312',
              'rotation': -45,
              'lineWidth': 6,
              'spacing': 10,
            },
          ] }
        />
      </>
    );
  }
}

export default ComparisonBarChart;
