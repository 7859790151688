//@flow
/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from 'react';
import Moment from 'react-moment';
import CopyToClipboard from 'react-copy-to-clipboard';

import type { HeaderObjectType } from '../../types/displaydatatypes';
import { formatMonetary } from './utils';

type Props = {
  headers: Array<HeaderObjectType>,
  rowData: Object,
  ignoredKeys: Array<string>,
  allowedKeys: Array<string>,
  columnClassName: string,
  onRowClick?: Function,
  customCellConfig: Array<Object>,
  extraRender?: Function | null,
  selected: boolean,
  isExpandable: boolean,
  index: number,
  onCopyClick: Function
};

type State = {
  expanded: boolean
};

const Styles = {
  columnP: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export const CellDateTime = ({ value }: {value: string }) => <>{value ? <Moment format='DD/MM/YY HH:mm:ss'>{value}</Moment> : 'N/A'}</>;

// Main row of the table
class DynamicTableRow extends PureComponent<Props, State> {
  static defaultProps = {
    rowData: {},
    ignoredKeys: [],
    allowedKeys: [],
    columnClassName: 'col col-lg-1',
    customCellConfig: [],
    extraRender: null,
    selected: false,
    isExpandable: false,
  }

  state = {
    expanded: false,
  }

  toggleExpanded = () => {
    this.setState((prevState: Object) => ({ expanded: !prevState.expanded }));
  }

  render() {
    const { expanded } = this.state;
    const {
      headers,
      rowData,
      ignoredKeys,
      allowedKeys,
      onRowClick,
      extraRender,
      customCellConfig,
      columnClassName,
      selected,
      index,
      isExpandable,
      onCopyClick,
    } = this.props;

    const headerKeys = headers.map((h: HeaderObjectType) => h.key);

    const copyIcon = (value: string) => (
      <CopyToClipboard text={ value }>
        <button
          className='dynamic-table-copy-button'
          type='button'
          onClick={ (e: Object) => {
            e.stopPropagation();
            onCopyClick();
          } }
        >
          <i className='material-icons'>content_copy</i>
        </button>
      </CopyToClipboard>
    );

    const expandableSectionKeys = {};
    if (allowedKeys.length) {
      allowedKeys.forEach((key: string) => {
        if (!headerKeys.includes(key) && rowData[key] !== undefined && rowData[key]) expandableSectionKeys[key] = rowData[key];
      });
    } else if (ignoredKeys.length) {
      Object.keys(rowData).forEach((key: string) => {
        if (!headerKeys.includes(key) && rowData[key] !== undefined && rowData[key] && !ignoredKeys.includes(key)) expandableSectionKeys[key] = rowData[key];
      });
    }

    const rowIsHoverable = (isExpandable && (Object.keys(expandableSectionKeys).length || extraRender));

    return (
      <>
        <div
          className={ `row data-row data-row-main${ selected || expanded ? ' active' : '' }${ rowIsHoverable ? ' hoverable' : '' }` }
          style={{ zIndex: 1 }}
          onClick={ () => {
            if (Object.keys(expandableSectionKeys).length || extraRender) this.toggleExpanded();
            if (onRowClick) onRowClick();
          } }
          key={ `data-row-${ index }` }
        >
          {isExpandable && (Object.keys(expandableSectionKeys).length || extraRender)
            ? (
              <i className='small-icon material-icons blue expander-icon' data-cy={ `data-cell-expander-${ index }` } style={{ zIndex: 1 }}>
                {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            ) : null
          }
          {headers.map((header: HeaderObjectType, headerIndex: number) => {
            const { key, copyable, cellCustomRender, dateFormat, monetaryFormat, size, cellStyle, currencyCode, currencyLocale } = header;
            const customElement = customCellConfig.find((e: Object) => e.key === key);

            // Determine cell formatting for all cells
            // Generic cell formatting
            let CellRender = (
              <p className='blue' style={ Styles.columnP }>
                <span>{String(rowData[key])}</span>
                { copyable && copyIcon(String(rowData[key])) }
              </p>
            );

            if (customElement) {
              CellRender = (
                <>{ customElement.renderFunction(rowData[key], rowData) }</>
              );
            } else
            // Determine cell formatting for main columns provided in header (overrides generic)
            // Custom cell configs for headers
            if (cellCustomRender) {
              CellRender = (
                <p style={ Styles.columnP }>
                  { cellCustomRender(rowData[key], rowData) }
                  { copyable && rowData[key] !== undefined && copyIcon(rowData[key]) }
                </p>
              );
            } else if (rowData[key] === undefined) {
              CellRender = (
                <p className='blue'>
                  <span>N/A</span>
                </p>
              );
            } else if (dateFormat) {
              CellRender = (
                <p className='blue' style={ Styles.columnP }>
                  <CellDateTime value={ rowData[key] } />
                  { copyable && copyIcon(rowData[key]) }
                </p>
              );
            } else if (monetaryFormat) {
              CellRender = (
                <p className='blue' style={ Styles.columnP }>
                  <span>{formatMonetary(rowData[key], 2, currencyLocale, currencyCode)}</span>
                  { copyable && copyIcon(formatMonetary(rowData[key], 2, currencyLocale, currencyCode)) }
                </p>
              );
            }

            return (
              <div
                className={ size ? `col col-lg-${ size } table-row-cell` : `${ columnClassName } table-row-cell` }
                style={{ ...cellStyle }}
                key={ `data-cell-${ index }-${ headerIndex }` }
                data-cy={ `data-cell-${ key }-${ index }` }
              >
                {CellRender}
              </div>
            );
          })}
        </div>

        {/* expanded data table row */}
        {isExpandable && (Object.keys(expandableSectionKeys).length || extraRender) && expanded
          ? (
            <div
              className='row data-row data-row-expanded-section active'
              key={ `data-row-${ index }-expanded` }
            >
              {Object.keys(expandableSectionKeys).map((key: string) => {
                const customElement = customCellConfig.find((e: Object) => e.key === key);

                return (
                  <Fragment key={ `data-row-expanded-section--${ index }-element-${ key }` }>
                    <div
                      className='col col-lg-3 data-row-expanded-section-element'
                      data-cy={ `data-cell-${ key }-${ index }` }
                    >
                      <p className='grey'>{(key.charAt(0).toUpperCase() + key.slice(1)).replace(/_/g, ' ')}</p>
                      {customElement
                        ? (
                          <div>
                            {/* TODO: also provide the entire data object */}
                            { customElement.renderFunction(rowData[key], rowData) }
                          </div>
                        ) : (
                          <p className='blue'>
                            {String(rowData[key])}
                          </p>
                        )}
                    </div>
                  </Fragment>
                );
              })}
              {extraRender
                ? (
                  <div className='col col-lg-12 data-row-expanded-section-extra-render' style={{ zIndex: 0 }}>
                    {extraRender(rowData, index)}
                  </div>
                ) : null
              }
            </div>
          ) : null
        }
      </>
    );
  }
}

export default DynamicTableRow;
