//@flow
import React from 'react';
import { Link } from 'react-router-dom';

import Opacity from '../../../UIComponents/Animations/Opacity';

type Props = {
  title?: string,
  url?: string,
  logo?: string,
  isActive?: boolean,
  onClick?: Function,
  icon?: string,
  extraConfig?: Object
};

const MenuItem = ({ title, url, logo, isActive, onClick, icon, extraConfig = {} }: Props) => {
  const [errorLoadingImage, setErrorLoadingImage] = React.useState(null);
  return (
    <div onClick={ onClick } className={ `aside-item menu-item ${ isActive ? 'active' : '' }` } data-tour={ (title || '').toLowerCase().split(' ').join('-') }>
      <Opacity>
        <Link className='menu-item-content' data-cy={ (title || '').toLowerCase().split(' ').join('-') } to={ url }>
          {/* No Icon and no Logo provided - display first letter in a circle */}
          {!icon && !logo
            ? <div className='circle' style={ extraConfig.customCss ? { ...extraConfig.customCss } : null }>{(title || 'N/A').slice(0, 1)}</div>
            : null
          }

          {/* Logo provided - display logo in a circle */}
          {(!icon && logo && !errorLoadingImage)
            ? (
              <div className='circle white'>
                <img
                  className='menu-logo'
                  alt='logo'
                  src={ logo }
                  onError={ (ev) => {
                    setErrorLoadingImage(true);
                    ev.target.style.display = 'none';
                  } }
                />
                {errorLoadingImage && (title || 'N/A').slice(0, 1)}
              </div>
            ) : null
          }

          {errorLoadingImage && <div className='circle' style={{ backgroundColor: '#3b86ff' }}>{(title || 'N/A').slice(0, 1)}</div>}

          {/* No logo but icon provided - display icon */}
          {icon && !logo
            ? <i className='material-icons'>{icon}</i>
            : null
          }

          <div className='menu-text'>{title}</div>
        </Link>
      </Opacity>
    </div>
  );
};


MenuItem.defaultProps = {
  title: '',
  url: '/',
  onClick: () => ({}),
  logo: '',
  isActive: false,
  icon: '',
};

export default MenuItem;
