//@flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../UIComponents/Avatar';
import DropDownContainer from '../../../UIComponents/DropDownContainer';

import './AppBar.scss';

type Props = {
  user: Object,
  incompleteTasks: Array<Object>,
  notifications: Array<Object>
};

type State = {
  tasksVisible: boolean,
  notificationsVisible: boolean
};

class AppBar extends PureComponent<Props, State> {
  static defaultProps = {
    user: null,
    incompleteTasks: [],
    notifications: [],
  }

  state = {
    tasksVisible: false,
    notificationsVisible: false,
  }

  toggleTasksVisible = () => {
    const { tasksVisible } = this.state;

    this.setState({ tasksVisible: !tasksVisible });
  }

  toggleNotificationsVisible = () => {
    const { notificationsVisible } = this.state;

    this.setState({ notificationsVisible: !notificationsVisible });
  }

  render() {
    const { tasksVisible, notificationsVisible } = this.state;
    const { user, incompleteTasks, notifications } = this.props;

    return (
      <div className='app-bar'>
        {incompleteTasks.length ? (
          <div className={ `icon-box ${ incompleteTasks.length ? 'has-circle' : '' }` } data-tour='appbar-incomplete-tasks-list-item'>
            <i className='material-icons' onClick={ this.toggleTasksVisible }>bookmarks</i>
            <DropDownContainer isActive={ tasksVisible } toggleFunction={ this.toggleTasksVisible }>
              <div className='incomplete-tasks-container'>
                <h4>Incomplete tasks</h4>
                {incompleteTasks.map((task: Object) => (
                  <div className='incomplete-tasks-list-item' key={ task.id }>
                    <div className='avatar-container'>
                      <div className='avatar-image'>
                        <i className={ task.icon } />
                      </div>

                      <div className='task-text'>
                        <p className='small blue'><b>{task.role} &gt; {task.title}</b></p>
                        <p className='small grey'>{ task.time}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </DropDownContainer>
          </div>
        ) : null}

        {notifications.length ? (
          <div className={ `icon-box ${ notifications.length ? 'has-circle' : '' }` } data-tour='appbar-alerts-notifications'>
            <i className='material-icons' onClick={ this.toggleNotificationsVisible }>notifications</i>
            <DropDownContainer isActive={ notificationsVisible } toggleFunction={ this.toggleNotificationsVisible }>
              <div className='notifications-container'>
                <h4>Notifications</h4>
                {notifications.map((notification: Object) => (
                  <div className='notifications-list-item' key={ notification.id }>
                    <div className='avatar-container'>
                      <div className='avatar-image'>
                        <i className={ notification.icon } />
                      </div>

                      <div className='notification-text'>
                        <p className='small blue'><b>{notification.title}</b> {notification.text}</p>
                        <p className='small grey'>{ notification.time}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </DropDownContainer>
          </div>
        ) : null}

        {user !== null && (
          <div className='user-box' data-tour='appbar-account-information'>
            <Avatar image={ user.avatar } text={ user.full_name } right hasDropdown>
              {/* User details box */}
              <div className='user-details'>
                {user.avatar ? <img src={ user.avatar } alt='avatar' /> : null}
                <p className='grey small'>Email</p>
                <p className='blue small'>{user.email_address}</p>
                <br />

                {/* User ID's */}
                {user.unique_employee_identifier && (
                  <>
                    <p className='grey small'>Unique Identifier</p>
                    <p className='blue small'>{user.unique_employee_identifier}</p>
                    <br />
                  </>
                )}
                {(user.person_id || user.employee_id) && <p className='grey small'>IDs:</p>}
                {user.person_id && <p className='blue small'>Person: {user.person_id}</p>}
                {user.employee_id && <p className='blue small'>Employee: {user.employee_id}</p>}
                {(user.person_id || user.employee_id) && <br />}

                {/* Entity data */}
                {user.employee_legal_entity_name && (
                  <>
                    <p className='grey small'>Entity</p>
                    <p className='blue small'>{user.employee_legal_entity_name}</p>
                    <br />
                  </>
                )}
                <Link to='/logout'> Log Out </Link>
              </div>
            </Avatar>
          </div>
        )}
      </div>
    );
  }
}

export default AppBar;
