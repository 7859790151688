//@flow
import React, { useState, useRef, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import type { DropDownSelectOptionType } from '../../types';

import DropDownContainer from '../DropDownContainer';

import './DropDownSelect.scss';

type Props = {
  name: string,
  placeholder: string,
  label: string,
  options: Array<DropDownSelectOptionType>,
  selectValue: Function,
  clearOnFocus: boolean,
  showId: boolean,
  optionsToShow: number,
  'data-tour': string,
  setFocus: boolean
};

const DropDownSelect = (
  {
    name = 'dropdown-select',
    placeholder = '',
    label = '',
    options = [],
    selectValue = () => null,
    clearOnFocus = true,
    showId = true,
    optionsToShow = 5,
    setFocus = false,
    'data-tour': dataTour,
  }: Props
) => {
  const [fieldValue, setFieldValue] = useState<string>('');
  const [cursor, setCursor] = useState<number>(0);
  const [redirectTo, setRedirectTo] = useState<string>('');
  const [showingAllItems, setShowingAllItems] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputRef = useRef<Object>();

  useEffect(() => {
    if (setFocus) {
      inputRef.current.focus();
    }
  }, [setFocus]);

  const listItems = options.filter((option: DropDownSelectOptionType) => (
    (option.title.toLowerCase().includes(fieldValue.toLowerCase()))
    || (option.value && String(option.value).toLowerCase().includes(fieldValue.toLowerCase()))
  ));

  const handleFieldUpdate = (e: SyntheticInputEvent<EventTarget>) => {
    const { target: { value } } = e;

    setFieldValue(value);
    setIsFocused(true);
    setCursor(0);
  };

  const handleFieldFocus = () => {
    if (clearOnFocus) {
      setIsFocused(true);
      setFieldValue('');
      setCursor(0);
    }
  };

  const handleKeyDown = (e: SyntheticKeyboardEvent<EventTarget>) => {
    const cursorRange = showingAllItems ? options.length - 1 : listItems.slice(0, optionsToShow).length - 1;

    const setRedirect = async () => setRedirectTo(listItems[cursor].url);

    // UP
    if (e.keyCode === 38) setCursor(cursor === 0 ? cursorRange : cursor - 1);

    // DOWN
    if (e.keyCode === 40) setCursor(cursor === cursorRange ? 0 : cursor + 1);

    // ESC
    if (e.keyCode === 27) setIsFocused(false);

    // ENTER
    if (e.keyCode === 13 && listItems[cursor] && listItems[cursor].value) {
      selectValue(listItems[cursor].value);
      setFieldValue(listItems[cursor].title);
      setIsFocused(false);

      if (listItems[cursor].url) setRedirect().then(() => setRedirectTo(''));
    }
  };

  const handleSelectOption = (item: DropDownSelectOptionType) => {
    if (item.value) {
      setIsFocused(false);
      setFieldValue(item.title);
      selectValue(item.value);
    }
  };

  const toggleShowingAllItems = () => {
    setShowingAllItems((prevShowingAllItems: boolean) => !prevShowingAllItems);
    setCursor(0);
  };

  const hideDropDown = () => setIsFocused(false);

  if (redirectTo) return <Redirect to={ redirectTo } />;

  const cursorRange = showingAllItems ? options.length : listItems.slice(0, optionsToShow).length;

  return (
    <div className='dropdown-select-container'>
      <label htmlFor={ name }>{label}</label>
      <div className='dropdown-select-form-goup'>
        <i className='material-icons'>search</i>
        <input
          className='dropdown-select-field'
          id={ name }
          name={ name }
          data-cy={ name }
          placeholder={ placeholder }
          type='text'
          autoComplete='off'
          value={ fieldValue }
          onChange={ handleFieldUpdate }
          onFocus={ !setFocus ? handleFieldFocus : undefined }
          onKeyDown={ handleKeyDown }
          data-tour={ dataTour }
          ref={ inputRef }
        />
      </div>
      <DropDownContainer isActive={ isFocused } toggleFunction={ hideDropDown }>
        <div className='dropdown-select-item-list'>
          {listItems.slice(0, cursorRange).map((option: DropDownSelectOptionType, index: number) => (
            option.url
              ? (
                <Link
                  to={ option.url }
                  className={ `dropdown-select-item ${ cursor === index ? 'active' : '' }` }
                  key={ option.value }
                  onClick={ () => handleSelectOption(option) }
                >
                  {option.value && showId ? <span>ID: {option.value}</span> : null} {option.title}
                </Link>
              ) : (
                <span
                  className={ `dropdown-select-item ${ cursor === index ? 'active' : '' }` }
                  key={ option.value }
                  onClick={ () => handleSelectOption(option) }
                >
                  {option.value && showId ? <span>ID: {option.value}</span> : null} {option.title}
                </span>
              )
          ))}
          {listItems.length > optionsToShow && (
            <span
              className='dropdown-select-item'
              style={{ textAlign: 'center' }}
              key='show-all'
              onClick={ () => toggleShowingAllItems() }
            >
              {showingAllItems ? <i className='material-icons blue'>keyboard_arrow_up</i> : <i className='material-icons'>keyboard_arrow_down</i> }
            </span>
          )}
          {listItems.length === 0 && (
            <span
              className='dropdown-select-item'
              key='show-all'
              onClick={ () => toggleShowingAllItems() }
            >
              <span> Nothing found for that search </span>
            </span>
          )}
        </div>
      </DropDownContainer>
    </div>
  );
};

export default DropDownSelect;
