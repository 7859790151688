//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';
import type { LocationObjectType } from '../../types';


const Breadcrumbs = ({ path, baseURL }: {path: Array<LocationObjectType>, baseURL: string}) => {
  return (
    <div className='breadcrumbs-container'>
      {path.map((page: LocationObjectType, i: number) => {
        if (i !== path.length - 1) {
          if (page.url) {
            return (
              <div key={ page.url } className='breadcrumb'>
                <Link to={ baseURL + page.url }>
                  <h3 className='title'>{page.title}</h3>
                </Link>
                <h3 className='title spacer'>&gt;</h3>
              </div>
            );
          }
          return (
            <div key={ page.title } className='breadcrumb'>
              <h3 className='title'>{page.title}</h3>
              <h3 className='title spacer'>&gt;</h3>
            </div>
          );
        }
        // Last breadrcumb does not have a link
        return (
          <div key={ page.title } className='breadcrumb'>
            <h3 className='title'>{page.title}</h3>
          </div>
        );
      })}
    </div>
  );
};
Breadcrumbs.defaultProps = {
  baseURL: '',
};

export default Breadcrumbs;
