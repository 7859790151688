//@flow
import React from 'react';

type Props = {
  title?: string
};

const Divider = ({ title }: Props) => (
  <div className={ `aside-item  ${ title ? 'divider' : 'line' }` }>
    {title}
  </div>
);

Divider.defaultProps = {
  title: '',
};

export default Divider;
