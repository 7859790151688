//@flow
/* eslint-disable no-restricted-globals */

// Required
export const required = (value: string | number) => (value ? undefined : 'Required');

// Max Length
// eg. maxLength(10)
export const maxLength = (max: number) => (value: string) => {
  return (value && value.length > max ? `Must be ${ max } characters or less` : undefined);
};

export const maxLength8 = maxLength(8);

// Min Length
// eg. minLength(10)
export const minLength = (min: number) => (value: string) => {
  return (value && value.length < min ? `Must be ${ min } characters or more` : undefined);
};
export const minLength2 = minLength(2);
export const minLength3 = minLength(3);
export const minLength8 = minLength(8);
export const minLength10 = minLength(10);
export const minLength15 = minLength(15);

// Set Length
// eg. setLength(10)
export const setLength = (len: number) => (value: string) => {
  return (value && value.length !== len ? `Must be ${ len } characters` : undefined);
};

export const setLength6 = setLength(6);
export const setLength8 = setLength(8);

// Is Number
export const isNumber = (value: string | number) => {
  return (value && isNaN(Number(value)) ? 'Must be a number' : undefined);
};

// Number min value (to be used with isNumber)
// eg. minValue(20)
export const minValue = (min: number) => (value: number) => {
  return (value && value < min ? `Must be at least ${ min }` : undefined);
};

// Number max value (to be used with isNumber)
// eg. maxValue(20)
export const maxValue = (max: number) => (value: number) => {
  return (value && value > max ? `Must be maximum ${ max }` : undefined);
};

// Is Alpha Numeric
export const isAlphaNumeric = (value: string) => {
  return (
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'Only alphanumeric characters'
      : undefined
  );
};

// Is Email
export const isEmail = (value: string) => {
  return (
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)
      ? 'Invalid email address'
      : undefined
  );
};

// Is Slug
export const isSlug = (value: string) => {
  return (
    value && /^[a-z0-9-_]+$/ig.test(value)
      ? 'Only letters, numbers, underscores or hyphens allowed'
      : undefined
  );
};
