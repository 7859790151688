//@flow
import React from 'react';
import styled from 'styled-components';

type Props = {
  orgName: string,
  color: string
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledText = styled.p`
  margin-top: 4px;
`;

const StyledBadge = styled.div`
  background: ${ ({ bgColor }: Object) => bgColor || '#000' };
  color: #fff;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 20px;
`;

const HostOrgName = (props: Props) => {
  const { orgName, color } = props;
  return (
    <StyledContainer style={{ padding: 0 }}>
      <StyledBadge bgColor={ color }>{orgName.slice(0, 1).toUpperCase()}</StyledBadge>
      <StyledText>{orgName}</StyledText>
    </StyledContainer>
  );
};

HostOrgName.defaultProps = {
  match: {
    path: '/identity-management',
    params: {},
  },
};

export default HostOrgName;
