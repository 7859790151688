//@flow
import React, { PureComponent } from 'react';

const { ResponsiveHeatMap } = require('@nivo/heatmap');

type Props = {
  data: Array<Object>,
  keys: Array<string>,
  indexBy: string,
  xLabel: string,
  yLabel: string,
  onClick: Function
};

const colors = ['#5CDD87', '#5CDD87', '#4FD07A', '#2EC962', '#27BE59', '#22B553', '#1AAC4B', '#0FA240', '#009933', '#0B8E37', '#238343'];

class HeatMap extends PureComponent<Props> {
  render() {
    const { data, keys, indexBy, xLabel, yLabel, onClick } = this.props;

    return (
      <ResponsiveHeatMap
        data={ data }
        keys={ keys }
        indexBy={ indexBy }
        style={{ fontSize: 20 }}
        margin={{
          'top': 60,
          'right': 0,
          'bottom': 20,
          'left': 80,
        }}
        axisTop={{
          'tickSize': 5,
          'tickPadding': 5,
          'tickRotation': 0,
          'legend': xLabel,
          'legendOffset': -40,
          'legendPosition': 'center',
        }}
        axisLeft={{
          'tickSize': 5,
          'tickPadding': 5,
          'tickRotation': -30,
          'legend': yLabel,
          'legendOffset': -70,
          'legendPosition': 'center',
        }}
        colors={ colors }
        isInteractive={ false }
        labelTextColor='#fff'
        cellOpacity={ 1 }
        cellBorderColor='inherit:darker(0.4)'
        animate
        motionStiffness={ 80 }
        motionDamping={ 9 }
        hoverTarget='cell'
        cellHoverOthersOpacity={ 0.25 }
        onClick={ onClick }
        theme={{
          labels: {
            text: {
              fontSize: 14,
              fontWeight: 900,
            },
          },
        }}
      />
    );
  }
}

export default HeatMap;
