//@flow
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { InfoBox } from '../UIComponents';

import type { InputObject, MetaObject } from './types';

type Props = {
  input: InputObject,
  label: string,
  description?: string,
  disabled: boolean,
  meta: MetaObject,
  options: Array<Object>,
  hasDefault: boolean
};


const SelectField = ({ input, label, description, options, hasDefault, disabled, meta: { touched, error, warning, submitError } }: Props) => {
  let fieldCssClasses = '';
  let labelCssClasses = '';

  if (touched && (error || submitError)) {
    fieldCssClasses += 'error';
    labelCssClasses += 'input-error';
  }

  if (touched && warning) {
    fieldCssClasses += 'warning';
    labelCssClasses += 'input-warning';
  }

  return (
    <>
      {label
        ? (
          <label htmlFor={ input.name }>
            <p className='grey form-field-label'>
              <span className={ labelCssClasses }>{label}</span>
              {touched && (error && <span className='input-error'> - {error}</span>)}
              {touched && !error && (warning && <span className='input-warning'> - {warning}</span>)}
            </p>
            <InfoBox description={ description } />
          </label>
        ) : null
      }

      <select { ...input } id={ input.name } data-cy={ input.name } className={ fieldCssClasses } disabled={ disabled }>
        {!hasDefault ? <option value={ null } /> : null}
        {options.map((option: Object) => (
          <option { ...option } key={ option.id } value={ option.id }>{option.value}</option>
        ))}
      </select>

      {touched && submitError && <p><span className='input-error'>{submitError}</span></p> }
    </>
  );
};

SelectField.defaultProps = {
  options: [],
  hasDefault: false,
  description: '',
};

export default SelectField;
