//@flow
import React from 'react';
import type { Node } from 'react';
import type { IncompleteTasksObjectType, MenuItemsObjectType, NotificationsObjectType } from '../../types/uicomponentstypes';
import AppBar from './AppBar';
import SideDrawer from './SideDrawer';


type Props = {
  user: Object,
  menuItems?: Array<MenuItemsObjectType>,
  incompleteTasks?: Array<IncompleteTasksObjectType>,
  notifications?: Array<NotificationsObjectType>,
  children: Node
};

const NavigationDrawer = ({ user, menuItems, incompleteTasks, notifications, children }: Props) => (
  <div className='navigation-drawer'>
    <div className='app-bar-container'>
      <AppBar
        user={ user }
        incompleteTasks={ incompleteTasks }
        notifications={ notifications }
      />
    </div>

    {/* Only render side drawer if menu items exist (allows to put the authentication in here as well) */}
    {((menuItems || []).length > 0) ? <div className='side-drawer-container'> <SideDrawer menuItems={ (menuItems || []) } /> </div> : null}

    <div className='main-container'>
      {children}
    </div>
  </div>
);

NavigationDrawer.defaultProps = {
  menuItems: [],
  incompleteTasks: [],
  notifications: [],
};

export default NavigationDrawer;
