//@flow
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './Tabs.scss';

type Props = {
  defaultActiveTabIndex: number,
  children: Object,
  isControlled: boolean,
  newTabTheme: boolean
};

type State = {
  activeTabIndex: number
};

class Tabs extends PureComponent<Props, State> {
  static propTypes = {
    defaultActiveTabIndex: PropTypes.number,
    children: PropTypes.node.isRequired,
    isControlled: PropTypes.bool, // eslint-disable-line
    newTabTheme: PropTypes.bool,
  };

  static defaultProps = {
    defaultActiveTabIndex: 0,
    isControlled: false,
  };

  state = {
    activeTabIndex: this.props.defaultActiveTabIndex, // eslint-disable-line
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { defaultActiveTabIndex, isControlled } = props;

    if (defaultActiveTabIndex !== state.activeTabIndex && isControlled) {
      return { activeTabIndex: defaultActiveTabIndex };
    }

    // Return null to indicate no change to state.
    return null;
  }

  // Toggle currently active tab
  handleTabClick = (tabIndex: number) => {
    this.setState({ activeTabIndex: tabIndex });
  };

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps() {
    const { activeTabIndex } = this.state;
    const { children } = this.props;

    return React.Children.map(children, (child: Object, index: number) => {
      return React.cloneElement(child, {
        handleClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === activeTabIndex,
      });
    });
  }

  // Render current active tab content
  renderActiveTabContent = () => {
    const { children } = this.props;
    const { activeTabIndex } = this.state;
    if (children[activeTabIndex]) {
      return children[activeTabIndex].props.children;
    }
    return null;
  };

  render() {
    const { newTabTheme } = this.props;
    return (
      <div className={ `tabs-container ${ newTabTheme ? 'newTabTheme' : '' }` }>
        <ul className='tabs-nav'>{this.renderChildrenWithTabsApiAsProps()}</ul>
        <div className='tabs-active-content'>{this.renderActiveTabContent()}</div>
      </div>
    );
  }
}

export default Tabs;
