//@flow
import React, { PureComponent, Fragment } from 'react';

import type { PieChartDataType } from '../../../types';

const { ResponsivePie } = require('@nivo/pie');

type Props = {
  data: Array<PieChartDataType>,
  lineWidth: number,
  defaultFontColor: string,
  animationSpeed: number
};

class LoanPieChart extends PureComponent<Props> {
  static defaultProps = {
    lineWidth: 5,
    defaultFontColor: '#4d4d4d',
    animationSpeed: 750,
  }

  render() {
    const { data, defaultFontColor, lineWidth, animationSpeed } = this.props;

    const chartData = data.map((dataPoint: PieChartDataType) => ({ value: parseFloat(dataPoint.value), id: dataPoint.name, label: dataPoint.name, color: dataPoint.color }));
    const chartColors = data.map((dataPoint: PieChartDataType) => dataPoint.color);

    return (
      <>
        <div style={{ width: '100%', height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ResponsivePie
            data={ chartData }
            margin={{
              'top': 10,
              'right': 10,
              'bottom': 10,
              'left': 10,
            }}
            innerRadius={ (100 - lineWidth) / 100 }
            padAngle={ 2 }
            startAngle={ 90 }
            endAngle={ 450 }
            cornerRadius={ 3 }
            colors={ chartColors }
            colorBy='id'
            borderWidth={ 1 }
            borderColor='inherit:darker(0.2)'
            slicesLabelsSkipAngle={ 20 }
            slicesLabelsTextColor={ defaultFontColor }
            enableRadialLabels={ false }
            enableSlicesLabels={ false }
            animate
            motionStiffness={ animationSpeed }
            motionDamping={ 15 }
            height={ 350 }
          />
          <div style={{ position: 'absolute' }}>
            {data.map((dataPoint: Object) => (
              <Fragment key={ dataPoint.name }>
                <p className='blue small text-center'>{dataPoint.name}:</p>
                <p>
                  <span style={{ height: 20, width: 20, borderRadius: 20, backgroundColor: dataPoint.color, position: 'absolute' }}>&nbsp;</span>
                  <sup style={{ marginLeft: 25, verticalAlign: 'super' }}><b>£</b></sup>
                  <b style={{ marginLeft: 5, color: dataPoint.color, fontSize: 25 }}>{dataPoint.value}</b>
                </p>
              </Fragment>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default LoanPieChart;
