//@flow
import React from 'react';

type Props = {
  tabName?: string,
  isActive?: boolean,
  extraClickAction: Function,
  handleClick: Function,
  tabIndex?: number,
  icon?: string,
  iconClassName?: string,
  'data-tour': string,
  'data-cy': string,
  smallLayout?: boolean
};

const Tab = (props: Props) => {
  const { isActive, tabIndex, icon, iconClassName, tabName, handleClick, extraClickAction, smallLayout } = props;
  return (
    <li
      className={ `tab ${ smallLayout ? 'tab--small' : '' }` }
      data-tour={ props['data-tour'] } // eslint-disable-line
      data-cy={ props['data-cy'] } // eslint-disable-line
    >
      <span
        className={ `tab-link ${ isActive ? 'active' : '' } ${ smallLayout ? 'small' : '' }` }
        onClick={ (event: any) => {
          event.preventDefault();
          handleClick(tabIndex);
          extraClickAction();
        } }
      >
        {icon ? <i className={ `material-icons ${ iconClassName || '' }` }>{icon}</i> : null}
        {tabName}
      </span>
    </li>
  );
};


Tab.defaultProps = {
  tabName: '',
  isActive: false,
  extraClickAction: () => {},
  tabIndex: 0,
  icon: '',
  iconClassName: '',
};

export default Tab;
