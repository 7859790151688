//@flow
import React from 'react';
import './InfoCard.scss';

type Props = {
  title: string,
  content: string,
  ['data-cy']: string | ''
};

const InfoCard = (props: Props) => {
  const { title, content } = props;
  return (
    <div
      className='info-card'
      data-cy={ props['data-cy'] || (title || '').toLowerCase().split(' ').join('-') } // eslint-disable-line
    >
      <p className='uppercase'>{title}</p>
      <h3
        className='blue'
        data-cy={ `${ props['data-cy'] }-value` } // eslint-disable-line
      >{content}</h3>
    </div>
  );
};


export default InfoCard;
