//@flow
import React from 'react';
import styled from 'styled-components';
import Grid from '../Grid';

const StyledCard = styled.div`
  background: white;
  border-radius: 10px;
  padding-bottom: 5px;
  margin-bottom: 2px;
  &:hover {
    background: ${ ({ hover }: Object) => {
    return hover ? '#DCE8F2' : 'white';
  } };
  }
`;

const StyledTop = styled.div`
  background: #022847;
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #3695c1;
  margin-bottom: 5px;
`;

const StyledTitle = styled.h3`
  color: white;
  font-weight: 300;
  font-size: 18px;
  margin: 12px 14px;
`;

type OtherProps = Object;

type Props = {
  title?: string,
  top?: React$Node,
  hover?: boolean,
  children: React$Node,
  ...OtherProps
};

const NewCard = ({ title, top, hover, children, ...props }: Props) => {
  if (title) {
    return (
      <StyledCard hover={ hover } { ...props }>
        <StyledTop>
          <Grid vertical='center'>
            <Grid.Section>
              <StyledTitle>{title}</StyledTitle>
            </Grid.Section>
          </Grid>
        </StyledTop>
        {children}
      </StyledCard>
    );
  }
  return (
    <StyledCard hover={ hover }>
      <StyledTop>{top}</StyledTop>
      {children}
    </StyledCard>
  );
};

export default NewCard;
