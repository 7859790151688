//@flow
import React, { PureComponent } from 'react';

import type { RadarChartDataType } from '../../../types';

const { ResponsiveRadar } = require('@nivo/radar');

type Props = {
  data: Array<RadarChartDataType>
};

class RadarChart extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    const chartData = data.map((dataPoint: RadarChartDataType) => ({
      label: dataPoint.label,
      value: parseFloat(dataPoint.value).toFixed(3),
    }));

    return (
      <div style={{ height: 350 }}>
        <ResponsiveRadar
          data={ [...chartData] }
          keys={ ['value'] }
          indexBy='label'
          maxValue={ 100 }
          margin={{ 'top': 70, 'bottom': 20 }}
          curve='cardinalClosed'
          borderWidth={ 2 }
          borderColor='inherit'
          gridLevels={ 5 }
          gridShape='circular'
          gridLabelOffset={ 36 }
          enableDots
          dotSize={ 8 }
          dotColor='inherit'
          dotBorderWidth={ 0 }
          dotBorderColor='#ffffff'
          enableDotLabel={ false }
          dotLabel='value'
          dotLabelYOffset={ -12 }
          colors='accent'
          colorBy='key'
          fillOpacity={ 0.5 }
          animate
          motionStiffness={ 90 }
          motionDamping={ 15 }
          isInteractive
        />
      </div>
    );
  }
}

export default RadarChart;
