//@flow
import React, { useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background: #dce8f2;
  border-radius: 50%;
  padding: 5% 0 0 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  box-shadow: 0px 3px 6px #00000034;
`;

const StyledContainer = styled.div`
  background: white;
  border-radius: 50%;
  height: 95%;
  width: 90%;
`;

const defaultColors = ['#3695c1', '#022847', '#e66203', '#bc1618'];

type Props = {
  data: string,
  color?: [string]
};

type CenteredDataProps = {
  centerX: number,
  centerY: number
};

const ArcGraph = ({ data, color = defaultColors }: Props) => {
  const [centeredContent, setCenteredContent] = useState<Object>(data[0]);

  const handleMouseEnter = (arc: Object) => {
    setCenteredContent(arc);
  };

  const handleMouseLeave = () => {
    setCenteredContent(data[0]);
  };

  const CenteredData = ({ centerX, centerY }: CenteredDataProps) => {
    return (
      <>
        {centeredContent.color && <circle cx={ centerX - 100 } cy={ centerY - 10 } r='5' fill={ centeredContent.color } />}
        {centeredContent.label && (
          <text
            x={ centerX - 80 }
            y={ centerY - 10 }
            textAnchor='start'
            dominantBaseline='central'
            fill='#707070'
            style={{
              fontSize: '20px',
              fontWeight: '300',
            }}
          >
            {centeredContent.label}
          </text>
        )}
        {centeredContent.value && (
          <text
            x={ centerX - 80 }
            y={ centerY + 30 }
            textAnchor='start'
            dominantBaseline='central'
            fill='#022847'
            style={{
              fontSize: '20px',
              fontWeight: '600',
            }}
          >
            {centeredContent.value}
          </text>
        )}
      </>
    );
  };
  return (
    <StyledWrapper>
      <StyledContainer>
        <ResponsivePie
          data={ data }
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          sortByValue={ false }
          innerRadius={ 0.9 }
          colors={ color }
          enableRadialLabels={ false }
          enableSliceLabels={ false }
          layers={ ['slices', 'sliceLabels', CenteredData] }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          activeOuterRadiusOffset={ 20 } // TODO: https://yobota.atlassian.net/browse/AUTH-142
          isInteractive
          tooltip={ () => null }
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default ArcGraph;
