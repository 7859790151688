//@flow
import React from 'react';
import './index.scss';

const ButtonsLoading = () => (
  <>
    <span className='loadingbutton' style={{ marginRight: 10 }} />
    <span className='loadingbutton' />
  </>
);

export default ButtonsLoading;
