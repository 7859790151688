//@flow
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import './Section.scss';
import { dataCYFormatter } from '../../Utils/dataCYFormatter';
type Props = {
  children: Node,
  title: string,
  startOpened?: boolean,
  additionalActions?: Node,
  arrowSpacing: boolean
};

type State = {
  isOpen?: boolean
};

class Section extends PureComponent<Props, State> {
  static defaultProps = {
    startOpened: false,
    additionalActions: null,
    arrowSpacing: false,
  }

  state = {
    isOpen: this.props.startOpened,
  }

  handleToggleOpen = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { children, title, additionalActions, arrowSpacing } = this.props;

    return (
      <div className='accordion-section-container' data-cy={ `${ dataCYFormatter(title) }-accordion-container` }>
        <div className='accordion-section-head' onClick={ this.handleToggleOpen }>
          <div className='accordion-section-head-content'>
            <p className='blue accordion-title' >{title}</p>
            {additionalActions || !arrowSpacing ? <i className='material-icons blue' style={{ cursor: 'pointer' }}>{isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i> : null}
          </div>
          <div className='accordion-section-head-actions'>
            {additionalActions || !arrowSpacing || <i className='material-icons blue' style={{ cursor: 'pointer' }} onClick={ this.handleToggleOpen }>{isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>}
          </div>
        </div>
        <div className={ `accordion-section-content ${ isOpen ? 'accordion-section-content-opened' : '' }` }>
          {children}
        </div>
      </div>
    );
  }
}

export default Section;
