//@flow
import React, { useState, useEffect, useRef } from 'react';
import Avatar from '../../../UIComponents/Avatar';
import Opacity from '../../../UIComponents/Animations/Opacity';

import DropDownContainer from '../../../UIComponents/DropDownContainer';

import Divider from './Divider';
import MenuItem from './MenuItem';
import type { MenuItemsObjectType } from '../../../types/uicomponentstypes';
import './SideDrawer.scss';

type Props = {
  menuItems: Array<MenuItemsObjectType>
};

const SideDrawer = ({ menuItems }: Props) => {
  const headerNode = useRef<Object>();
  const dropDownNode = useRef<Object>();
  const [holeListExpanded, setHoleListExpanded] = useState(false);

  const handleClick = (e: Object) => {
    if (!dropDownNode.current) return;
    if (headerNode.current.contains(e.target) || dropDownNode.current.contains(e.target)) return;
    setHoleListExpanded(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  if (!menuItems.length) return null;

  let holesMenuItems = [];
  let rolesMenuItems = [];
  let activeHole = {};

  menuItems.forEach((item: Object) => {
    if (
      item.type === 'divider'
      || item.type === 'line-divider'
      || item.type === 'menu_item_host'
      || item.type === 'menu_item_entity'
    ) {
      holesMenuItems = [...holesMenuItems, item];
      if (item.isActive) {
        activeHole = item;
      }
    } else {
      rolesMenuItems = [...rolesMenuItems, item];
    }
  });

  return (
    <div className='aside'>
      <div
        className='aside-header'
        data-tour='sidebar-default-legal-entity'
        ref={ headerNode }
      >
        <Avatar
          image={ activeHole.logo }
          text={ activeHole.title }
          hasDropdown
          controlled
          arrowOpen={ holeListExpanded }
          customCss={ (activeHole.extraConfig || {}).customCss }
          onClick={ () => setHoleListExpanded(!holeListExpanded) }
        />
        <DropDownContainer isActive={ holeListExpanded } ref={ dropDownNode }>
          <div className='entities-and-host-orgs'>
            <div data-tour='sidebar-additional-entities-and-host-orgs'>
              <Opacity>
                {holesMenuItems.map((menuItem: Object) => {
                  if (menuItem.type === 'divider') {
                    return <Divider key={ `menu-divider-for-${ menuItem.title }` } title={ menuItem.title } />;
                  }

                  if (menuItem.type === 'line-divider') {
                    return <Divider key={ `menu-divider-for-${ menuItem.title }` } />;
                  }

                  return (
                    <MenuItem
                      key={ `menu-item-for-${ menuItem.title }` }
                      title={ menuItem.title }
                      url={ menuItem.url }
                      onClick={ () => { menuItem.onClick(); setHoleListExpanded(false); } }
                      logo={ menuItem.logo }
                      isActive={ menuItem.isActive }
                      extraConfig={ menuItem.extraConfig || {} }
                    />
                  );
                })}
              </Opacity>
            </div>
          </div>
        </DropDownContainer>
      </div>

      <div data-tour='sidebar-roles-list'>
        <Opacity>
          {rolesMenuItems.map((menuItem: Object) => {
            return (
              <MenuItem
                title={ menuItem.title }
                url={ menuItem.url }
                cssClass={ menuItem.cssClass }
                isActive={ menuItem.isActive }
                icon={ menuItem.icon }
                key={ `menu-item-for-${ menuItem.title }` }
              />
            );
          })}
        </Opacity>
      </div>
    </div>
  );
};

export default SideDrawer;
