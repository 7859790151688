//@flow
import React from 'react';
import { FormSpy } from 'react-final-form';

type Props = {
  warning: Function
};

const InputWarning = ({ warning }: Props) => <FormSpy subscription={{ values: true }} onChange={ warning } />;

export default InputWarning;
