//@flow
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';

type InputObject = {
  name: string,
  value: boolean
};

type Props = {
  input: InputObject,
  label: string,
  disabled?: boolean
};

const CheckboxField = ({ input, label, disabled }: Props) => {
  return (
    <>
      <label htmlFor={ input.name } className='checkbox-container'>
        <input
          { ...input }
          type='checkbox'
          id={ input.name }
          data-cy={ input.name }
          checked={ !!input.value }
          disabled={disabled}
        />
        <span className='checkmark' />
        <p className='grey form-field-label'>
          <span className={ disabled ? 'disabled' : '' }>{label}</span>
        </p>
      </label>
    </>
  );
};

export default CheckboxField;
