//@flow
import React from 'react';
import InfoBox from '../InfoBox';

type GridProps = {
  children?: React$Node,
  className?: string,
  props?: Object
};

type SectionProps = {
  children?: React$Node,
  className?: string,
  props?: Object,
  portion?: number
};

type ContentProps = {
  label: React$Node,
  content?: React$Node,
  portion?: number,
  children?: React$Node,
  description?: string
};

const Grid = ({ children, className = '', ...props }: GridProps) => (
  <div { ...props } className={ `row ${ className }` }>
    {children}
  </div>
);

const Section = ({ children, portion = 12, className = '', ...props }: SectionProps) => (
  <div { ...props } className={ `col col-lg-${ portion } ${ className }` }>
    {children}
  </div>
);

Grid.Section = Section;

const Content = ({ portion, label = '', content, description, children, ...props }: ContentProps) => (
  <Grid.Section { ...props } portion={ portion }>
    <p className='grey'>
      {label}
      {description && <InfoBox description={ description } />}
    </p>
    {content && <p>{content}</p>}
    {children}
  </Grid.Section>
);

const Line = () => (
  <Grid.Section><hr /></Grid.Section>
);

Grid.Content = Content;
Grid.Line = Line;

export default Grid;
