//@flow
import React from 'react';
import './index.scss';
import TextLoading from './TextLoading';

const CheckboxWithTextLoading = () => (
  <div className='loadingCheckboxWithText__wrap'>
    <span className='loadingCheckboxWithText__checkbox' />
    <p className='loadingCheckboxWithText__text'><TextLoading /></p>
  </div>
);

export default CheckboxWithTextLoading;
