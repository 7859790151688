//@flow
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import PropTypes from 'prop-types';
import './Tabs.scss';

type Props = {
  defaultActiveTabIndex: number,
  children?: Node,
  isControlled: boolean,
  smallLayout: boolean,
  tabsStyle: Object
};

type State = {
  activeTabIndex: number
};

class SimpleTabs extends PureComponent<Props, State> {
  static propTypes = {
    defaultActiveTabIndex: PropTypes.number,
    children: PropTypes.node.isRequired,
    isControlled: PropTypes.bool, // eslint-disable-line
    smallLayout: PropTypes.bool,
    tabsStyle: PropTypes.object,
  }

  static defaultProps = {
    defaultActiveTabIndex: 0,
    isControlled: false,
    smallLayout: false,
  }

  state = {
    activeTabIndex: this.props.defaultActiveTabIndex, // eslint-disable-line
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { defaultActiveTabIndex, isControlled } = props;

    if (defaultActiveTabIndex !== state.activeTabIndex && isControlled) {
      return ({ activeTabIndex: defaultActiveTabIndex });
    }

    // Return null to indicate no change to state.
    return null;
  }

  // Toggle currently active tab
  handleTabClick = (tabIndex: number) => {
    this.setState({ activeTabIndex: tabIndex });
  }

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps(): Array<any> {
    const { activeTabIndex } = this.state;
    const { children, smallLayout } = this.props;

    const allTabs = React.Children.map(children, (child: any, index: number) => {
      if (child) {
        return React.cloneElement(child, {
          handleClick: this.handleTabClick,
          tabIndex: index,
          isActive: index === activeTabIndex,
          smallLayout,
        });
      }
      return null;
    });

    return allTabs.filter((child: any) => child);
  }

  // Render current active tab content
  renderTabsContent = () => {
    const { children } = this.props;
    const { activeTabIndex } = this.state;

    //$FlowFixMe
    return children.map((child: Object, index: number) => {
      if (child) {
        if (index === activeTabIndex) {
          return (
            <div className='tabs-content active' key={ `tab-${ index }` } data-cy={ `tab-${ index }` }>
              { child.props.children }
            </div>
          );
        }
        return (
          <div className='tabs-content' key={ `tab-${ index }` } data-cy={ `tab-${ index }` }>
            { child.props.children }
          </div>
        );
      }
      return null;
    });
  }

  render() {
    const { smallLayout, tabsStyle } = this.props;
    return (
      <div className='tabs-container'>
        <ul className={ `tabs-nav ${ smallLayout ? 'tabs-nav--small' : '' }` } style={ tabsStyle }>
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>

        {this.renderTabsContent()}
      </div>
    );
  }
}

export default SimpleTabs;
