//@flow
import React from 'react';
import './Timeline.scss';
import type { StepObjectType } from '../../types';

type Props = {
  steps: Array<StepObjectType>,
  currentStep: number,
  jumpToPage: Function
};

const Timeline = ({ steps, currentStep, jumpToPage }: Props) => {
  const progress = (100 / (steps.length)) * (currentStep);

  return (
    <div className='steps-timeline'>
      {steps.map((step: Object) => (
        <div className='steps' key={ step.number } style={{ width: `${ 100 / steps.length }%` }}>
          <span
            className={ `steps-circle ${ step.number < currentStep ? 'done' : '' } ${ step.number === currentStep ? 'current' : '' }` }
            onClick={ () => jumpToPage(step.number) }
          >
            {step.number < currentStep ? <i className='material-icons small-icon'>done</i> : step.number}
          </span>
          <p className='grey steps-name'>{step.name}</p>
        </div>
      ))}

      <div
        className='progress-line'
        style={{ width: `calc( ${ progress }% - ${ 100 / (steps.length) / 2 }%)` }}
      />
    </div>
  );
};

Timeline.defaultProps = {
  steps: [],
  currentStep: 0,
  jumpToPage: () => {},
  progressBarOffset: 0,
};

export default Timeline;
