//@flow
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import moment from 'moment';
import type { HeaderObjectType } from '../../types/displaydatatypes';
import DynamicTableRow from './DynamicTableRow';
import DynamicTableHeader from './DynamicTableHeader';
import Pagination from '../../UIComponents/Pagination';
import DropDownContainer from '../../UIComponents/DropDownContainer';
import SortByList from '../../UIComponents/SortByList';

import './DynamicTable.scss';

const { CSVLink } = require('react-csv');

type Props = {
  headers: Array<HeaderObjectType>,
  data: Array<Object>,
  ignoredKeys: Array<string>,
  allowedKeys: Array<string>,
  onRowClick: Function,
  rowExtraRender?: Function | null,
  selectedRow: number,
  isExpandable: boolean,
  isSelectable: boolean,
  customCellConfig: Array<Object>,
  CSVdownloadable: boolean,
  CSVHeaderConfig: Array<Object>,
  CSVFileName: string,
  tableHeader: string,
  advancedFeatures: boolean,
  defaultPageSize: number,
  defaultSortBy: string,
  defaultOrder: 'asc' | 'desc',
  onCopyClick: Function
};

type State = {
  selectedRow: number,
  filters: Object,
  currentPage: number,
  sortBy: string | null,
  displayingSorting: boolean,
  pageSize: number,
  displayPageSize: boolean,
  order: 'asc' | 'desc'
};

const CSVButtonStyles = {
  position: 'sticky',
  padding: 0,
  float: 'right',
  bottom: 0,
  lineHeight: 'unset',
  transform: 'translate(-20%, -50%)',
  zIndex: 9,
  minWidth: 'unset',
  borderRadius: 50,
  height: 40,
  width: 40,
  paddingTop: 8,
};

class DynamicTable extends PureComponent<Props, State> {
  static defaultProps = {
    ignoredKeys: [],
    allowedKeys: [],
    onRowClick: () => {},
    customCellConfig: [],
    rowExtraRender: null,
    selectedRow: -1,
    isExpandable: false,
    isSelectable: false,
    CSVdownloadable: false,
    CSVHeaderConfig: [],
    CSVFileName: 'my-csv',
    tableHeader: '',
    advancedFeatures: false,
    defaultPageSize: 5,
    defaultSortBy: '',
    defaultOrder: 'asc',
    onCopyClick: () => {},
  };

  state = {
    selectedRow: this.props.selectedRow,
    filters: {},
    currentPage: 0,
    sortBy: this.props.advancedFeatures ? this.props.defaultSortBy || (this.props.headers.find((header: HeaderObjectType) => header.sortable) || {}).key : null,
    displayingSorting: false,
    pageSize: this.props.defaultPageSize || 5,
    displayPageSize: false,
    order: this.props.defaultOrder,
  };

  handleSelectRow = (rowIndex: number) => {
    const { onRowClick, isSelectable } = this.props;

    onRowClick(rowIndex);
    this.setState({ selectedRow: isSelectable ? rowIndex : -1 });
  };

  // Filtering
  changeFilter = (key: string, value: string) => {
    const { filters } = this.state;

    const newFilters = { ...filters };
    newFilters[key] = value;

    this.setState({ filters: newFilters, selectedRow: -1, currentPage: 0 });
  };

  // Pagination
  changePage = (page: number) => {
    this.setState({ currentPage: page });
  };

  toggleDisplayingPageSize = () => {
    this.setState((prevState: State) => ({ displayPageSize: !prevState.displayPageSize }));
  };

  setPageSize = (pageSize: number) => {
    this.setState({ pageSize, displayPageSize: false, currentPage: 0 });
  };

  // Sorting
  toggleDisplayingSorting = () => {
    this.setState((prevState: State) => ({ displayingSorting: !prevState.displayingSorting }));
  };

  changeSortBy = (sortBy: string) => {
    this.setState({ sortBy, displayingSorting: false, currentPage: 0 });
  };

  changeOrder = (order: 'asc' | 'desc') => {
    this.setState({ order });
  };

  handleHeaderCellClick = (sortBy: string, order: 'asc' | 'desc') => {
    this.setState({ sortBy, order });
  };

  render() {
    const { selectedRow, filters, currentPage, sortBy, displayingSorting, displayPageSize, pageSize, order } = this.state;
    const {
      headers,
      data, // basic render information
      ignoredKeys,
      allowedKeys,
      isExpandable, // expandable rows configuration
      rowExtraRender,
      customCellConfig, // custom cell renders
      CSVdownloadable,
      CSVHeaderConfig,
      CSVFileName, // CSV download configuration
      tableHeader,
      advancedFeatures, // fancy row above header
      onCopyClick, // Copy to clipboard click
    } = this.props;
    const columnClassName = `col col-lg-${ Math.floor(12 / headers.length) }`;

    const displayedData = data.filter((dataSet: Object) => {
      let objectValid = true;

      Object.keys(filters).forEach((key: string) => {
        if (filters[key].length && !filters[key].includes(dataSet[key])) objectValid = false;
      });

      return objectValid;
    });

    let finalData = displayedData;

    const sortableBy = headers.filter((header: HeaderObjectType) => header.sortable);

    if (advancedFeatures && sortableBy.length && sortBy) {
      const sortingKey = (headers || []).find((h: HeaderObjectType) => h.key === sortBy);

      if (order === 'asc') {
        finalData = finalData.sort((a: Object, b: Object) => (sortingKey && sortingKey.dateFormat ? moment.utc(a[sortBy]).diff(moment.utc(b[sortBy])) : a[sortBy] - b[sortBy]));
      }
      if (order === 'desc') {
        finalData = finalData.sort((a: Object, b: Object) => (sortingKey && sortingKey.dateFormat ? moment.utc(b[sortBy]).diff(moment.utc(a[sortBy])) : b[sortBy] - a[sortBy]));
      }
    }

    if (advancedFeatures && pageSize) {
      finalData = displayedData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    }

    const sizeOptions = [];
    for (let i = 5, j = 0; i <= data.length && j < 3; i += 5, j++) {
      sizeOptions.push(i.toString());
    }
    sizeOptions.push(data.length.toString());

    return (
      <>
        <div className={ `data-table dynamic-table${ isExpandable ? ' expandable' : ' not-expandable' }` }>
          {(tableHeader || advancedFeatures) && (
            <div className='row'>
              {tableHeader && (
                <div className='col col-lg-4'>
                  <h3 className='table-header-title'>{tableHeader}</h3>
                </div>
              )}
              <div className={ `col col-lg-${ tableHeader ? '8' : '12' } table-advanced-controls` }>
                {advancedFeatures ? (
                  <>
                    <div className='control table-advanced-controls-pagination'>
                      <Pagination
                        currentPage={ currentPage }
                        totalPages={ parseInt(displayedData.length / pageSize, 10) + (displayedData.length % pageSize ? 1 : 0) }
                        onPageClick={ this.changePage }
                        totalEntries={ displayedData.length }
                      />
                    </div>
                    <div className='control'>
                      <>
                        <div className='control-columns'>
                          <span className='table-advanced-controls-page-size' onClick={ this.toggleDisplayingPageSize }>
                            <p className='small grey'>
                              Page Size:&nbsp;{pageSize}
                              <i style={{ float: 'right' }} className='material-icons blue'>
                                {displayingSorting ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                              </i>
                            </p>
                          </span>
                          <DropDownContainer isActive={ displayPageSize } toggleFunction={ this.toggleDisplayingPageSize }>
                            <SortByList options={ sizeOptions } onSelect={ this.setPageSize } />
                          </DropDownContainer>
                        </div>
                      </>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          )}

          <DynamicTableHeader
            headers={ headers }
            columnClassName={ columnClassName }
            data={ data }
            handleFilterUpdate={ this.changeFilter }
            sortBy={ sortBy }
            order={ order }
            headerCellClick={ advancedFeatures ? this.handleHeaderCellClick : () => null }
          />

          {finalData.map((dataSet: Object, index: number) => (
            <DynamicTableRow
              index={ index }
              headers={ headers }
              rowData={ dataSet }
              customCellConfig={ customCellConfig }
              ignoredKeys={ ignoredKeys }
              allowedKeys={ allowedKeys }
              columnClassName={ columnClassName }
              onRowClick={ () => this.handleSelectRow(index) }
              extraRender={ rowExtraRender }
              selected={ selectedRow === index }
              isExpandable={ isExpandable }
              key={ `data-row-${ index }-${ currentPage }` }
              onCopyClick={ onCopyClick }
            />
          ))}
        </div>
        {CSVdownloadable ? (
          <div className='btn btn-success' style={{ ...CSVButtonStyles, marginRight: 10 }}>
            <CSVLink data={ finalData } filename={ `${ CSVFileName }.csv` } headers={ CSVHeaderConfig.length ? CSVHeaderConfig : null } target='_blank'>
              <i className='material-icons' style={{ position: 'relative', left: 'unset' }}>
                cloud_download
              </i>
            </CSVLink>
          </div>
        ) : null}
      </>
    );
  }
}

export default DynamicTable;
