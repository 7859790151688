//@flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const themes = {
  colors: {
    secondary: '#3695C1',
    secondaryHover: '#2b7598',
    primary: '#ffffff',
  },
};

const StyledButton = styled.button`
  background-color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.primary : themes.colors.secondary) };
  color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.secondary : themes.colors.primary) };
  border-color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.secondary : '') };
  font-weight: 100;
  font-size: 15px;
  :hover {
    background-color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.primary : themes.colors.secondaryHover) };
    color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.secondaryHover : themes.colors.primary) };
    border-color: ${ ({ priority }: Object) => (priority === 'primary' ? themes.colors.secondaryHover : '') };
  }
`;

type othersType = Object;

type Props = {
  text: string,
  priority?: string,
  to?: string,
  onClick?: Function,
  ...othersType,
};

const Button = ({ text = 'button', priority = 'primary', to, onClick, ...others }: Props) => {
  if (to) {
    return (
      <Link to={ to }>
        <StyledButton className='btn' priority={ priority } { ...others }>
          {text}
        </StyledButton>
      </Link>
    );
  }
  return (
    <StyledButton className='btn' priority={ priority } onClick={ onClick } { ...others }>
      {text}
    </StyledButton>
  );
};

export default Button;
