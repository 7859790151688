//@flow
import React, { PureComponent } from 'react';

const { ResponsiveBullet } = require('@nivo/bullet');

// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.

type Props = {
  data: Array<Object>
};

class BulletChart extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <ResponsiveBullet
        data={ data }
        margin={{
          'top': 50,
          'right': 90,
          'bottom': 50,
          'left': 90,
        }}
        spacing={ 46 }
        measureColors='white'
        rangeColors={ ['#3b86ff', '#002039', '#009933'] }
        titleAlign='start'
        titleOffsetX={ -70 }
        measureSize={ 0.2 }
        animate
        motionStiffness={ 90 }
        motionDamping={ 12 }
      />);
  }
}
export default BulletChart;
