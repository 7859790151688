//@flow
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { InfoBox } from '../UIComponents';
import { toNumber, numberFormatter } from '../Utils/numberFormatter';

import type { InputObject, MetaObject } from './types';

type Props = {
  autoComplete?: string,
  input: InputObject,
  label: string,
  description?: string,
  placeholder?: string,
  step?: number,
  min: number,
  max: number,
  disabled: boolean,
  formatted: boolean,
  decimals: number,
  meta: MetaObject
};

const NumberField = ({ autoComplete, input, label, description, placeholder, step, min, max, disabled, formatted, decimals, meta: { parse, touched, error, warning, submitError } }: Props) => {
  let fieldCssClasses = '';
  let labelCssClasses = '';

  if (touched && (error || submitError)) {
    fieldCssClasses += 'error';
    labelCssClasses += 'input-error';
  }

  if (touched && warning) {
    fieldCssClasses += 'warning';
    labelCssClasses += 'input-warning';
  }

  document.addEventListener('wheel', () => {
    if (document.activeElement instanceof HTMLElement !== 'number') {
      if (document.activeElement !== null) document.activeElement.blur();
    }
  });


  const inputType = !formatted ? 'number' : null;

  const updateValue = (event) => {
        // Stop cursor jumping when formatting number in React
        const caret = event.target.selectionStart;
        const element = event.target;
        window.requestAnimationFrame(() => {
          element.selectionStart = caret;
          element.selectionEnd = caret;
        });

      const val = event.target.value.replace(/(\..*)\./g, '$1'); // Replace multiple dots/decimal separators
      const numberValue = toNumber(val);
      event.target.value = numberFormatter(numberValue, decimals);
  }

  return (
    <>
      {label
        ? (
          <label htmlFor={ input.name || '' }>
            <p className='grey form-field-label'>
              <span className={ labelCssClasses }>{label}</span>
              {touched && (error && <span className='input-error'> - {error}</span>)}
              {touched && !error && (warning && <span className='input-warning'> - {warning}</span>)}
            </p>
            <InfoBox description={ description } />
          </label>
        ) : null
      }

      <input
        { ...input }
        id={ input.name }
        data-cy={ input.name }
        placeholder={ placeholder }
        className={ fieldCssClasses }
        type={inputType}
        min={ min }
        max={ max }
        step={ step }
        disabled={ disabled }
        autoComplete={ autoComplete }
        onBlur={(e) => {
          if (formatted) updateValue(e)
        }}
      />

      {touched && submitError && <p><span className='input-error'>{submitError}</span></p> }
    </>
  );
};

NumberField.defaultProps = {
  placeholder: '',
  step: 1,
  description: '',
  autoComplete: 'on',
  formatted: false,
  decimals: 2
};

export default NumberField;
