//@flow
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { InfoBox } from '../UIComponents';

import type { InputObject, MetaObject } from './types';

type Props = {
  autoComplete?: string,
  input: InputObject,
  label: string,
  description?: string,
  placeholder?: string,
  type?: string,
  disabled: boolean,
  meta: MetaObject
};

const TextField = ({ autoComplete, input, label, description, placeholder, type, disabled, meta: { touched, error, warning, submitError } }: Props) => {
  let fieldCssClasses = '';
  let labelCssClasses = '';

  if (touched && (error || submitError)) {
    fieldCssClasses += 'error';
    labelCssClasses += 'input-error';
  }

  if (touched && warning) {
    fieldCssClasses += 'warning';
    labelCssClasses += 'input-warning';
  }

  return (
    <>
      {label
        ? (
          <label htmlFor={ input.name }>
            <p className='grey form-field-label'>
              <span className={ labelCssClasses }>{label}</span>
              {touched && (typeof error === 'string' && <span className='input-error'> - {error}</span>)}
              {touched && !error && (warning && <span className='input-warning'> - {warning}</span>)}
            </p>
            <InfoBox description={ description } />
          </label>
        ) : null
      }

      <input
        { ...input }
        id={ input.name }
        data-cy={ input.name }
        placeholder={ placeholder }
        type={ type }
        disabled={ disabled }
        className={ fieldCssClasses }
        autoComplete={ autoComplete }
      />
      {touched && submitError && <p><span className='input-error'>{submitError}</span></p> }
    </>
  );
};

TextField.defaultProps = {
  placeholder: '',
  description: '',
  type: 'text',
  autoComplete: 'on',
};

export default TextField;
