//@flow
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';

import Opacity from '../Animations/Opacity';

import './Dialog.scss';

type Props = {
  title: string,
  content: string,
  continueText: string,
  cancelText: string,
  isSuccess: boolean,
  isFail: boolean,
  customHeaderIcon: string,
  children: Node,
  isControlled: boolean,
  isVisible: boolean,
  customStyle: Object,
  hasURL: boolean,
  redirectURL: string,
  centered: boolean,
  handleCancel: Function,
  handleContinue: Function,
  newDialogTheme?: boolean,
  isClose: string
};

type State = {
  isVisibleState: boolean
};

class Dialog extends PureComponent<Props, State> {
  static defaultProps = {
    title: '',
    content: '',
    continueText: '',
    handleContinue: () => {},
    cancelText: '',
    handleCancel: () => {},
    isSuccess: false,
    isFail: false,
    customHeaderIcon: '',
    children: null,
    isControlled: false,
    isVisible: false,
    customStyle: {},
    hasURL: false,
    redirectURL: '',
    centered: false,
    newDialogTheme: false,
    isClose: '',
  };

  state = {
    isVisibleState: this.props.isControlled ? this.props.isVisible : true,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const { isControlled, isVisible } = props;
    const { isVisibleState } = state;

    if (isControlled && isVisibleState !== isVisible) {
      return { isVisibleState: isVisible };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyDown);
  }

  handleCloseOverlay = () => {
    this.setState({ isVisibleState: false });
  };

  handleContinue = () => {
    const { handleContinue } = this.props;

    handleContinue();
    this.handleCloseOverlay();
  };

  handleCancel = () => {
    const { handleCancel } = this.props;

    handleCancel();
    this.handleCloseOverlay();
  };

  handleKeyDown = (e: SyntheticKeyboardEvent<EventTarget>) => {
    const { handleCancel } = this.props;
    const { isVisibleState } = this.state;

    if (e.keyCode === 27 && isVisibleState) {
      // UP
      e.preventDefault();
      handleCancel();
      this.handleCloseOverlay();
    }
  };

  render() {
    const { isVisibleState } = this.state;
    const {
      customStyle,
      title,
      content,
      continueText,
      cancelText,
      isSuccess,
      isFail,
      customHeaderIcon,
      children,
      hasURL,
      redirectURL,
      centered,
      newDialogTheme,
      isClose,
    } = this.props;

    return (
      <div className={ newDialogTheme ? 'dialog-container newDialogTheme' : 'dialog-container' } data-cy='dialog-box' tabIndex='0' onKeyDown={ this.handleKeyDown } role='button'>
        {isVisibleState ? (
          <div className='overlay' style={ centered ? { paddingBottom: 0 } : null }>
            {/* Close overlay */}
            {!hasURL ? <div className='dialog-close-overlay' onClick={ this.handleCancel } /> : null}
            {/* Dialog window */}
            <Opacity speed={ 300 }>
              <div className='dialog-content'>
                {!cancelText && !continueText ? <span className='dialog-corner-close small-circle close' onClick={ this.handleCancel } /> : null}
                <div className='dialog-content-header'>
                  {isSuccess && (
                    <div className='dialog-content-header-success'>
                      <i className='material-icons'>check</i>
                    </div>
                  )}
                  {isFail && (
                    <div className='dialog-content-header-fail'>
                      <i className='material-icons'>close</i>
                    </div>
                  )}
                  {customHeaderIcon && (
                    <div className='dialog-content-header-generic'>
                      {/* <i className='material-icons'>{customHeaderIcon}</i> */}
                      <i className={ customHeaderIcon } />
                    </div>
                  )}
                  {isClose && (
                    <div className='dialog-content-header-close' onClick={ this.handleCancel }>
                      <i className='material-icons'>close</i>
                    </div>
                  )}
                </div>
                <div className='dialog-content-message' style={ customStyle }>
                  {title ? <h3 className='title'>{title}</h3> : null}
                  {content ? <p>{content}</p> : null}
                  {children ? <>{children}</> : null}
                </div>
                {cancelText || continueText ? (
                  <>
                    {!hasURL ? (
                      <div className='dialog-content-footer' style={ customStyle }>
                        {cancelText ? (
                          <span className='btn btn-error' onClick={ this.handleCancel }>
                            {cancelText}
                          </span>
                        ) : null}
                        {continueText ? (
                          <span className='btn btn-success' onClick={ this.handleContinue }>
                            {continueText}
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      <Link to={ redirectURL }>
                        <div className='dialog-content-footer' style={ customStyle }>
                          {cancelText ? (
                            <span className='btn btn-error' onClick={ this.handleCancel }>
                              {cancelText}
                            </span>
                          ) : null}
                          {continueText ? (
                            <span className='btn btn-success' onClick={ this.handleContinue }>
                              {continueText}
                            </span>
                          ) : null}
                        </div>
                      </Link>
                    )}
                  </>
                ) : null}
              </div>
            </Opacity>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Dialog;
