//@flow
import React from 'react';

import './SortByList.scss';

type Props = {
  options: Array<string>,
  onSelect: Function
};

const SortByList = ({ options, onSelect }: Props) => {
  return (
    <>
      {options.map((opt: string) => {
        return (
          <span className='sort-item' onClick={ () => onSelect(opt) } key={ opt }>
            {opt}
          </span>
        );
      })}
    </>
  );
};


SortByList.defaultProps = {
};

export default SortByList;
