//@flow
import { PureComponent } from 'react';
import type { Node } from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  location: any,
  children: Node,
  containerName: string
};

class ScrollToTop extends PureComponent<Props> {
  static defaultProps = {
    containerName: 'main-content',
  }

  componentDidUpdate(prevProps: Props) {
    const { location, containerName } = this.props;

    if (location !== prevProps.location) {
      const mainContent = document.getElementById(containerName);
      if (mainContent) mainContent.scrollTop = 0;
    }
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default withRouter(ScrollToTop);
