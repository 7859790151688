//@flow
import React, { PureComponent } from 'react';

const { ResponsiveLine } = require('@nivo/line');

// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.

type Props = {
  data: Array<Object>
};

class LineChart extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <ResponsiveLine
        data={ data }
        margin={{
          'top': 50,
          'right': 160,
          'bottom': 150,
          'left': 60,
        }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day' }}
        yScale={{
          'type': 'linear',
          'stacked': false,
          'min': 'auto',
          'max': 'auto',
        }}
        curve='linear'
        axisTop={ null }
        axisRight={ null }
        axisBottom={{
          format: '%d %b %Y',
          'tickRotation': 60,
        }}

        axisLeft={{
          'orient': 'left',
          'tickSize': 5,
          'tickPadding': 5,
          'tickRotation': 0,
          'legend': 'amount(£)',
          'legendOffset': -40,
          'legendPosition': 'middle',
        }}
        colors='nivo'
        dotSize={ 10 }
        dotColor='inherit:darker(0.3)'
        dotBorderWidth={ 2 }
        dotBorderColor='#ffffff'
        enableDotLabel
        dotLabel='y'
        dotLabelYOffset={ -12 }
        animate
        motionStiffness={ 90 }
        motionDamping={ 15 }
        legends={ [
          {
            'anchor': 'bottom-right',
            'direction': 'column',
            'justify': false,
            'translateX': 160,
            'translateY': -60,
            'itemsSpacing': 0,
            'itemDirection': 'left-to-right',
            'itemWidth': 145,
            'itemHeight': 20,
            'itemOpacity': 0.75,
            'symbolSize': 12,
            'symbolShape': 'circle',
            'symbolBorderColor': 'rgba(0, 0, 0, .5)',
            'effects': [
              {
                'on': 'hover',
                'style': {
                  'itemBackground': 'rgba(0, 0, 0, .03)',
                  'itemOpacity': 1,
                },
              },
            ],
          },
        ] }
      />);
  }
}
export default LineChart;
