//@flow
import React from 'react';
import type { Node } from 'react';
import './Card.scss';

type Props = {
  children?: Node,
  isHoverable?: boolean,
  isRounded?: boolean,
  style?: Object,
  highlight?: string,
  ['data-tour']: ?string,
  // $FlowFixMe
  ['data-cy']: ?string
};

const Card = (props: Props) => {
  const { children, isHoverable, isRounded, style, highlight } = props;

  return (
    <div
      className={ `card ${ highlight || '' } ${ isHoverable ? 'card-hoverable' : '' } ${ isRounded ? 'card-rounded' : '' }` }
      style={ style }
      data-tour={ props['data-tour'] } // eslint-disable-line
      // $FlowFixMe
      data-cy={ props['data-cy'] } // eslint-disable-line
    >
      <div className='card-content'>
        {children}
      </div>
    </div>
  );
};

Card.defaultProps = {
  children: null,
  isHoverable: false,
  isRounded: false,
  style: {},
  highlight: '',
};

export default Card;
