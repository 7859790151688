//@flow
import React from 'react';

type SectionProps = {
  children?: React$Node,
  className?: string,
  props?: Object,
  portion?: number
};

type OtherProps = {
  props: Object
};

type GridProps = {
  ...OtherProps,
  children?: React$Node,
  full?: boolean,
  className?: string,
  props?: Object
};

const Section = ({ children, portion, className = '', ...props }: SectionProps) => {
  const newClassName = portion ? `col col-lg-${ portion } ${ className }` : `col col-lg-12 ${ className }`;
  return (
    <div { ...props } className={ newClassName }>{children}</div>
  );
};

const Grid = ({ children, full, className = '', ...props }: GridProps) => {
  return (
    <div className='row'>
      {full ? <Section { ...props } className={ `col col-lg-12 ${ className }` }>{children}</Section> : children}
    </div>
  );
};


Grid.Section = Section;
export default Grid;
