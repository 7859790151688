/* eslint-disable jsx-a11y/label-has-associated-control */
//@flow
import React from 'react';

type Props = {
  input: {
    name: string
  },
  meta: {
    touched: boolean,
    error: ?string,
    submitError: ?string
  },
  label: string
};

const DateField = ({ input, meta: { touched, error, submitError }, label = '' }: Props) => {
  const showError = touched && (error || submitError);
  return (
    <div>
      {label.length > 0 && (
        <label htmlFor={ input.name }>
          <p className='grey form-field-label'>
            <span className={ showError ? 'input-error' : '' }>{label}</span>
            {touched && error && <span className='input-error'> - {error}</span>}
          </p>
        </label>
      )}
      <input { ...input } type='date' data-cy={ input.name } id={ input.name } className={ showError ? 'error' : '' } max='9999-12-31' />
      {touched && submitError && (
        <p>
          <span className='input-error'>
            <b>{submitError}</b>
          </span>
        </p>
      )}
    </div>
  );
};

export default DateField;
