// @flow
import React, { type Node, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import styled from 'styled-components';

// TODO: FEAT-573 fix UX bug and bring in the overlay

// const Overlay = styled.div`
//   background-color: #000000;
//   opacity: 0.4;
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   z-index: 1;
// `;

const HtmlPopOver = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#707070',
    maxWidth: 240,
    padding: 30,
    fontSize: 14,
    left: 60,
    borderRadius: 10,
    top: -17,
    '&.MuiTooltip-tooltipPlacementLeft': {
      left: -550,
    },
    '&.MuiTooltip-arrow': {
      right: -9,
    },
    '& p:first-child': {
      marginTop: 0,
    },
  },
  arrow: {
    color: '#FFFFFF',
    marginLeft: '-19px !important',
    width: '1.71em !important',
    height: '2em !important',
    top: '26px !important',
  },
}))(Tooltip);

type Props = {
  description?: Node,
  children?: Node,
  placement?: string,
  showPopOver: boolean
};

const PopOver = ({ description, showPopOver, placement = 'right-start', children }: Props) => {
  const [open, setOpen] = useState(showPopOver);

  useEffect(() => {
    setOpen(showPopOver);
  }, [showPopOver]);

  const handlePopOverClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* {open && <Overlay onClick={ handlePopOverClose } />} */}
      <HtmlPopOver
        placement={ placement }
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        onClose={ handlePopOverClose }
        open={ open }
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={ Zoom }
        title={ (
          <>
            <div>{description}</div>
          </>
        ) }
      >
        {children}
      </HtmlPopOver>
    </>
  );
};

export default PopOver;
