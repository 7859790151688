//@flow
import React, { PureComponent } from 'react';
import DropDownContainer from '../DropDownContainer';

import './InfoBox.scss';

type Props = {
  description: string
};

type State = {
  open: boolean
};

class InfoBox extends PureComponent<Props, State> {
  static defaultProps = {
    description: '',
  }

  state = {
    open: false,
  }

  toggleOpen = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const { open } = this.state;
    const { description } = this.props;

    if (!description) return null;

    return (
      <span className='info-box-container'>
        <i className='material-icons' onClick={ this.toggleOpen } style={ open ? { color: '#002847' } : null }>info</i>
        <DropDownContainer isActive={ open } toggleFunction={ this.toggleOpen }>
          <p className='small grey'>{description}</p>
        </DropDownContainer>
      </span>
    );
  }
}

export default InfoBox;
