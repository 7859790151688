//@flow
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import DropDownContainer from '../DropDownContainer';
import './Avatar.scss';

type Props = {
  image: string,
  icon: string,
  text: string,
  supportText: string,
  right: boolean,
  hasDropdown: boolean,
  children: Node,
  controlled: boolean,
  arrowOpen: boolean,
  customCss: Object,
  onClick: () => void
};

type State = {
  dropdownOpen: boolean,
  errorImage: boolean
};

class Avatar extends PureComponent<Props, State> {
  static defaultProps = {
    image: '',
    text: '',
    supportText: '',
    icon: '',
    right: false,
    hasDropdown: false,
    children: null,
    controlled: false,
    arrowOpen: false,
    customCss: {},
    onClick: () => {},
  }

  state = {
    dropdownOpen: false,
    errorImage: false,
  }

  handleToggleDropdown = () => {
    this.setState((prevState: State) => ({ ...prevState, dropdownOpen: !prevState.dropdownOpen }));
  }

  handleErrorImage = () => {
    this.setState((prevState: State) => ({ ...prevState, errorImage: true }));
  }

  displayAvatar = () => {
    const { image, icon, customCss, text } = this.props;
    const { errorImage } = this.state;

    if (image && !errorImage) {
      return (
        <img
          alt='avatar'
          src={ image }
          onError={ (ev) => {
            this.handleErrorImage();
            ev.target.style.display = 'none';
          } }
        />
      );
    }

    if (icon) {
      return <i className={ icon } />;
    }

    return <div className='circle' style={{ backgroundColor: '#3b86ff', ...customCss }}>{text.slice(0, 1)}</div>;
  }

  render() {
    const { text, supportText, right, hasDropdown, children, controlled, arrowOpen, onClick } = this.props;
    const { dropdownOpen } = this.state;
    const isOpened = controlled ? arrowOpen : dropdownOpen;

    return (
      <div className={ `avatar-container ${ hasDropdown ? 'avatar-container-with-dropdown' : '' }` } onClick={ onClick }>
        <div className='avatar-content' onClick={ this.handleToggleDropdown }>
          <div className='avatar-image' style={{ order: right ? 3 : 0 }}>
            {this.displayAvatar()}
          </div>

          {hasDropdown && (
            <div className='avatar-dropdown' style={{ order: 2 }}>
              <i className='material-icons'>{isOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
            </div>
          )}

          {text && (
            <div className='avatar-text' style={{ order: 1 }}>
              {text}
              {supportText && <p className='small grey'>{supportText}</p>}
            </div>
          )}
        </div>

        {hasDropdown && dropdownOpen && children && (
          <DropDownContainer isActive={ dropdownOpen } toggleFunction={ this.handleToggleDropdown }>
            { children }
          </DropDownContainer>
        )}
      </div>
    );
  }
}


export default Avatar;
