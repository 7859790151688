//@flow
import React from 'react';
import { InfoBox } from '../UIComponents';

import type { InputObject, MetaObject } from './types';

type Props = {
  input: InputObject,
  label: string,
  description?: string,
  disabled: boolean,
  meta: MetaObject
};

const DateFieldRF = ({ input, label, description, disabled, meta: { touched, error, warning, submitError } }: Props) => {
  let fieldCssClasses = '';
  let labelCssClasses = '';

  if (touched && (error || submitError)) {
    fieldCssClasses += 'error';
    labelCssClasses += 'input-error';
  }

  if (touched && warning) {
    fieldCssClasses += 'warning';
    labelCssClasses += 'input-warning';
  }

  return (
    <>
      {label
        ? (
          <label htmlFor={ input.name }>
            <p className='grey form-field-label'>
              <span className={ labelCssClasses }>{label}</span>
              {touched && (typeof error === 'string' && <span className='input-error'> - {error}</span>)}
              {touched && !error && (warning && <span className='input-warning'> - {warning}</span>)}
            </p>
            <InfoBox description={ description } />
          </label>
        ) : null
      }

      <input
        { ...input }
        id={ input.name }
        data-cy={ input.name }
        type='date'
        disabled={ disabled }
        className={ fieldCssClasses }
        autoComplete='off'
        max='9999-12-31'
      />

      {touched && submitError && <p><span className='input-error'>{submitError}</span></p> }
    </>
  );
};

DateFieldRF.defaultProps = {
  description: '',
};

export default DateFieldRF;
