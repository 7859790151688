//@flow
import React, { PureComponent } from 'react';

const TICKER_INTERVAL = 1000; // 1 second

type Props = {
  expirationTime?: number
};

type State = {
  timer: ?IntervalID,
  counter: number,
  isVisible: boolean
};


class LoadingSpinner extends PureComponent<Props, State> {
  static defaultProps = {
    expirationTime: 10,
  }

  state = {
    timer: null,
    counter: 0,
    isVisible: true,
  }

  componentDidMount() {
    this.startTokenTimer();
  }

  componentWillUnmount() {
    const { timer } = this.state;

    if (timer) {
      clearInterval(timer);
    }
  }

  startTokenTimer = () => {
    const timer = setInterval(this.tick, TICKER_INTERVAL);
    this.setState({ timer });
  }

  tick = () => {
    const { counter } = this.state;

    this.setState({ counter: counter + 1 });
  }

  closeLoadingSpinner = () => {
    this.setState({ isVisible: false });
  }

  render() {
    const { counter, isVisible } = this.state;
    const { expirationTime } = this.props;

    if (!isVisible) return null;

    return (
      <div className='overlay'>
        <div className='spinner' data-cy='loading-spinner'>
          <div className='sk-fading-circle'>
            <div className='sk-circle1 sk-circle' />
            <div className='sk-circle2 sk-circle' />
            <div className='sk-circle3 sk-circle' />
            <div className='sk-circle4 sk-circle' />
            <div className='sk-circle5 sk-circle' />
            <div className='sk-circle6 sk-circle' />
            <div className='sk-circle7 sk-circle' />
            <div className='sk-circle8 sk-circle' />
            <div className='sk-circle9 sk-circle' />
            <div className='sk-circle10 sk-circle' />
            <div className='sk-circle11 sk-circle' />
            <div className='sk-circle12 sk-circle' />
          </div>
        </div>
        <div className='spinner-message'>
          {(expirationTime && counter > expirationTime) || counter > 300
            ? (
              <div className='spinner-message-container'>
                <p>The network is slow to respond or something went wrong.</p><br />
                <p>You can either wait for the data to be processed or retry to perform the action.</p><br />
                <span className='btn btn-error' onClick={ this.closeLoadingSpinner }>close</span>
              </div>
            ) : null
          }
          {expirationTime === 0 && counter > 10 && counter < 300
            ? (
              <div className='spinner-message-container'>
                <p>This action takes some time</p>
                <p>Please do not refresh the page or use the back button.</p>
              </div>
            ) : null
          }
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
