//@flow
import React, { Component } from 'react';
import type { Node } from 'react';

type Props = {
  children: Node
};

type State = {
  error: null | Object,
  errorInfo: null | Object
};


class ErrorBoundary extends Component<Props, State> {
  state = { error: null, errorInfo: null }

  componentDidCatch(error: Object, errorInfo: Object) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      return (
        <div className='main-container-content'>
          <h3 className='title error'>Something went wrong.</h3>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary>{error && error.toString()}</summary>
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
