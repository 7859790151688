// @flow
import React, { type Node, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PopOver from '../PopOver';

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  padding-left: 5px;

  span {
    color: #f0475b;
    font-weight: bold;
    font-family: 'system-ui';
  }
`;

const HelpIcon = styled.div`
  background-color: ${ (showPopOver: boolean) => (showPopOver ? '#022847' : '#E2F0F7') };
  color: ${ (showPopOver: boolean) => showPopOver && '#FFFFFF' };
  border: 1px solid '#022847';
  border-radius: 50%;
  height: 24px;
  display: flex;
  width: 24px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin: 0 2px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const unRotate = keyframes`
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const IndicatorIcon = styled.div`
  background-color: #022847;
  color: #ffffff;
  border-radius: 50%;
  height: 24px;
  display: flex;
  width: 24px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin: 0 2px;
  animation: ${ unRotate } 200ms;
  animation-fill-mode: forwards;
  &.hovered {
    animation: ${ rotate } 200ms;
    animation-fill-mode: forwards;
  }
`;
type Props = {
  title: string,
  children?: Node,
  helpText?: string,
  indicator?: boolean,
  theme?: Object,
  popOverPlacement?: string,
  startOpen?: boolean,
  additionalActions?: Node
};

const defaultTheme = {
  wrapperBG: '#F6F8FB',
  detailBG: '#FFFFFF',
  summary: ' #2B3246',
  summaryHoverBG: '#E2F0F7',
  summaryExpandedBG: '#E2F0F7',
  summaryExpandedHoverBG: '#F6F8FB',
};

const NewAccordion = ({ title, children, popOverPlacement, helpText, indicator, theme, startOpen, additionalActions }: Props) => {
  const [expanded, setExpanded] = useState(startOpen || false);
  const [showPopOver, setShowPopOver] = useState(false);
  const [hoverState, setHoverState] = useState(false);

  const updatedTheme = {
    ...defaultTheme,
    ...theme,
  };

  const Wrapper = withStyles({
    root: {
      borderRadius: '10px !important',
      backgroundColor: updatedTheme.wrapperBG,
      zIndex: 1,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
      '&.send-forward': {
        zIndex: 2,
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionDetails = withStyles({
    root: {
      padding: 0,
      backgroundColor: updatedTheme.detailBG,
      display: 'block',
    },
  })(MuiAccordionDetails);

  const AccordionSummary = withStyles({
    root: {
      marginBottom: -1,
      transition: 'background .5s',
      minHeight: 56,
      color: updatedTheme.summary,
      background: '#E8F2FA',
      '&$expanded': {
        minHeight: 56,
      },
      '&:hover': {
        background: updatedTheme.summaryHoverBG,
      },
      '&.Mui-expanded:hover': {
        background: updatedTheme.summaryExpandedHoverBG,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '14px 10px',
    },
    expanded: {},
  })(MuiAccordionSummary);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleMouseEnter = () => {
    if (indicator) {
      setHoverState(true);
    }
  };

  const handleMouseLeave = () => {
    if (indicator) {
      setHoverState(false);
    }
  };

  const handlePopOver = (value: boolean) => setShowPopOver(value);

  return (
    <>
      <Wrapper square={ false } expanded={ expanded } onChange={ handleChange(!expanded) } theme={ theme }>
        <AccordionSummary
          aria-controls='panel1d-content'
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          id='panel1d-header'
          className={ expanded ? 'expanded' : '' }
          theme={ theme }
        >
          <Title>{title}</Title>
          {(helpText || indicator || additionalActions) && (
            <FormControlLabel
              control={ (
                <>
                  {helpText && (
                    <PopOver description={ helpText } showPopOver={ showPopOver } placement={ popOverPlacement }>
                      <HelpIcon
                        onFocus={ event => event.stopPropagation() }
                        onClick={ event => {
                          event.stopPropagation();
                          handlePopOver(!showPopOver);
                        } }
                        // showPopOver={ showPopOver }
                      >
                        ?
                      </HelpIcon>
                    </PopOver>
                  )}
                  {additionalActions && additionalActions}
                  {indicator && (
                    <IndicatorIcon className={ hoverState ? 'hovered' : '' }>
                      {expanded ? <i className='material-icons'>keyboard_arrow_up</i> : <i className='material-icons'>keyboard_arrow_down</i>}
                    </IndicatorIcon>
                  )}
                </>
              ) }
              label={ null }
            />
          )}
        </AccordionSummary>
        <AccordionDetails theme={ theme }>{children}</AccordionDetails>
      </Wrapper>
    </>
  );
};

export default NewAccordion;
