import { isNat, isNum, isStr, str, isFin } from 'fpx';

const DEFAULT_FRACTION_DIGITS = 2;
const FORMAT_LOCALE = 'en-GB';

// Regex for numeric values only
const NUMBER_REGEX = /^[+-]?\d+(?:[.]\d+)?$/

const isStringNumeric = (value) => {
    return NUMBER_REGEX.test(value);
}

const maybeFinite = (value) => {
    return isFin(value) ? value : undefined;
}

/*
Differences from `Number.parseFloat`:
* accepts nil, string, or number; in the latter case performs no conversion;
    rejects other inputs
  * requires the input to be a string, treating `null` and `undefined` as `''`
  * requires the entire string to be a valid number; won't parse something like
    `'123.345blah'` into something like `123.456`
  * returns either finite number or `undefined`, never `NaN` or `Infinity`
*/
const parseNum = (value) => {
    if (isNum(value)) {
        return maybeFinite(value);
    }

    value = str(value);

    if (isStringNumeric(value)) {
        value = parseFloat(value, 10);
        return maybeFinite(value);
    }

    return undefined;
}

export const toNumber = (value) => {
    if (isNum(value)) return value;
    return parseNum(value.replace(/,/g, ""));

}

export const numberFormatter = (value, decimals) => {
    if (!isNat(decimals)) decimals = DEFAULT_FRACTION_DIGITS;
    return new Intl.NumberFormat(FORMAT_LOCALE, { minimumFractionDigits: decimals }).format(toNumber(value));
}