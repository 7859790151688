//@flow
import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Animations.scss';

type Props = {
  children: Node,
  speed: number
};

type State = {
  mounted: boolean
};

class Opacity extends PureComponent<Props, State> {
  static defaultProps = {
    children: null,
    speed: 500,
  }

  state = {
    mounted: false,
  }

  componentDidMount() {
    this.toggleMounted(true);
  }

  toggleMounted = (val: boolean) => {
    this.setState({ mounted: val });
  }

  render() {
    const { mounted } = this.state;
    const { speed, children } = this.props;

    return (
      <CSSTransition
        in={ mounted }
        timeout={ speed }
        classNames={ `fade-${ 100 * Math.floor(speed / 100) }` }
        unmountOnExit
      >
        <>
          {children}
        </>
      </CSSTransition>
    );
  }
}

export default Opacity;
