//@flow
import React from 'react';
import InputRange from 'react-input-range';
import type { ValueObjectType } from '../../types';

import './LinearFilter.scss';

type Props = {
  value: ValueObjectType,
  onChange: Function,
  label?: string,
  min?: number | string,
  max?: number | string,
  step?: number
};

const LinearFilter = ({ min, max, value, onChange, label, step }: Props) => {
  return (
    <div className='grid-container' style={{ width: 300, textAlign: 'left', marginBottom: 20 }}>
      <div className='row'>
        <div className='col col-lg-12'>
          <p className='small grey'>{label}</p>
          <InputRange
            allowSameValues
            value={ value }
            onChange={ onChange }
            minValue={ min }
            maxValue={ max }
            step={ step }
          />
        </div>
      </div>
    </div>
  );
};

LinearFilter.defaultProps = {
  label: '',
  min: 0,
  max: 0,
  step: 1,
};

export default LinearFilter;
