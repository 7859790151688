//@flow
import React from 'react';
import './Pagination.scss';

type Props = {
  onPageClick: Function,
  currentPage: number,
  totalPages: number,
  totalEntries?: number
};

const Pagination = (props: Props) => {
  const { onPageClick, currentPage, totalPages, totalEntries } = props;
  const allPages = Array(totalPages).fill(null).map((x: Object, i: number) => i);

  return (
    <div className='pagination-container'>
      <p className='small'>
        {totalEntries
          ? <span className='grey'>Total {totalEntries} items</span>
          : null
        }

        {totalPages > 1 && currentPage > 2 && (
          <span className='grey pagination-button' onClick={ () => onPageClick(currentPage > 0 ? currentPage - 1 : 0) }>&lt;</span>
        )}

        {(currentPage) > 1 && (
          <>
            <span
              className='grey pagination-button'
              onClick={ () => onPageClick(0) }
            >1</span>
            {(currentPage) > 2 && (<span className='grey'>&middot;&middot;&middot;</span>)}
          </>
        )}

        {totalPages > 1 && allPages.map((pageNumber: number) => {
          if (Math.abs(pageNumber - currentPage) > 1) return null;
          return (
            <span
              className={ `grey pagination-button ${ pageNumber === currentPage ? 'selected' : '' }` }
              key={ pageNumber }
              onClick={ () => onPageClick(pageNumber) }
            >{pageNumber + 1}</span>
          );
        })}

        {(totalPages - currentPage) > 2 && (
          <>
            {(totalPages - currentPage) > 3 && (<span className='grey'>&middot;&middot;&middot;</span>)}
            <span
              className='grey pagination-button'
              onClick={ () => onPageClick(totalPages - 1) }
            >{totalPages}</span>
          </>
        )}
        {totalPages > 1 && currentPage < totalPages - 3 && (
          <span className='grey pagination-button' onClick={ () => onPageClick(currentPage < totalPages - 1 ? currentPage + 1 : totalPages - 1) }>&gt;</span>
        )}
      </p>
    </div>
  );
};

export default Pagination;
