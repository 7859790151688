//@flow
import React from 'react';
// $FlowFixMe
import Select, { components } from 'react-select';
import propTypes from 'prop-types';
import InfoBox from '../../UIComponents/InfoBox';
import type { HeaderObjectType } from '../../types';

type Props = {
  headers: Array<HeaderObjectType>,
  columnClassName?: string,
  data?: Array<Object>,
  handleFilterUpdate: Function,
  sortBy: string | null,
  order: 'asc' | 'desc',
  headerCellClick: Function
};

type DropdownSelectProps = {
  key: string,
  columnClassName: string,
  header: any,
  options: Array<Object>,
  filters: Object,
  handleFilterUpdate: Function
};

const DynamicTableHeader = ({ headers, columnClassName, data, handleFilterUpdate, sortBy, order, headerCellClick }: Props) => {
  let hasFilters = false;
  const filters = {};

  const setFilterOptions = (headerData: Array<Object>, headerDataObject: Object) => {
    return [...new Set((headerData || [])
      .map((dataSet: Object) => dataSet[headerDataObject.key]))]
      .map((d: string) => ({
        value: d,
        label: String(d),
      }));
  };

  headers.forEach((headerObject: HeaderObjectType) => {
    if (headerObject.filterable) {
      const filterOptions = setFilterOptions(data, headerObject);
      filters[headerObject.key] = [...filterOptions];
      if (filterOptions.length > 1) hasFilters = true;
    }
  });

  return (
    <>
      <div className='row header sticky' style={{ zIndex: 3 }} key='dynamic-table-header'>
        {headers.map((header: HeaderObjectType) => {
          return (
            <div
              className={ header.size ? `col col-lg-${ header.size } table-header-cell` : `${ columnClassName } table-header-cell` }
              key={ header.key }
              onClick={ () => {
                if (header.sortable) headerCellClick(header.key, order === 'desc' ? 'asc' : 'desc');
              } }
            >
              <p className={ `grey ${ header.sortable ? 'hoverable' : '' }` }>
                {header.title}
                {sortBy === header.key && (<i className='material-icons'>{order === 'desc' ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>)}
                {header.description
                  ? (
                    <InfoBox description={ header.description } />
                  ) : null
                }
              </p>
            </div>
          );
        })}
      </div>

      {hasFilters
        ? (
          <div className='row header sticky filter-row' style={{ top: 50, zIndex: 2 }} key='dynamic-table-header-filters'>
            {headers.map((header: HeaderObjectType) => {
              let filterOptions = [];
              if (header.filterable) {
                filterOptions = setFilterOptions(data, header);
              }

              return (
                <DropdownSelect
                  key={ header.key }
                  header={ header }
                  options={ filterOptions }
                  filters={ filters }
                  columnClassName={ columnClassName }
                  handleFilterUpdate={ handleFilterUpdate }
                />
              );
            })}
          </div>
        ) : null
      }
    </>
  );
};

const DropdownSelect = ({ header, options, filters, columnClassName, handleFilterUpdate }: DropdownSelectProps) => {

  // eslint-disable-next-line flowtype/require-parameter-type
  const setFilterValue = (value) => {
    return (value || []).map((v: Object) => v.value);
  };

  const Option = (props: DropdownSelectProps) => {
    // eslint-disable-next-line react/prop-types
    const { data: { label }, innerProps: { key } } = props;
    return (
      <components.Option { ...props }>
        <span className='data-table-select__option__custom' data-cy={ `data-cy-${ label }` } key={ key }>
          { label }
        </span>
      </components.Option>
    );
  };

  return (
    <div
      className={ header.size ? `col col-lg-${ header.size }` : columnClassName }
      key={ `${ header.key }-filter` }
      data-cy={ `data-cy-${ header.key }-filter` }
    >
      {filters[header.key] && filters[header.key].length > 1
        ? (
          <Select
            key={ header.key }
            components={{
              Option,
            }}
            isMulti
            classNamePrefix='data-table-select'
            placeholder='Search'
            options={ options }
            onChange={ (value: Object) => handleFilterUpdate(header.key, setFilterValue(value)) }
          />
        ) : null
      }
    </div>
  );
};

DynamicTableHeader.defaultProps = {
  columnClassName: 'col col-lg-1',
  data: [],
  handleFilterUpdate: () => {},
};

export default DynamicTableHeader;
